import { useRef, useState } from "react";
import { PostArticle } from "../../../domain/usecases/article/post-article";
import { Box, Button, TextField, Typography } from "@mui/material";

import { Editor } from 'primereact/editor';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useArticleStore } from "../../states/article-store";
import LoadingDialog from "../../components/dialog/loading-dialog";


type CreateArticlePageProps = {
  postArticle: PostArticle;
};

const CreateArticlePage = ({ postArticle }: CreateArticlePageProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');
  const { setShouldRefresh } = useArticleStore(); 
  const titleRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const title = titleRef.current?.value;

    if (!title || !content) {
      toast.error('Title and content are required');
      return;
    }

    setIsLoading(true);

    try {
      var created = await postArticle.post({ title, content });
      setShouldRefresh(true);
      navigate(`/artigos/s/${created.slug}`);
    } catch (error: any) {
      toast.error('Error creating article: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit}
      sx={{ 
        maxWidth: '600px', 
        margin: 'auto', 
        padding: '2rem', 
        display: 'flex', 
        flexDirection: 'column',
        gap: '1.5rem' 
      }}
    >
      <Typography variant="h4" gutterBottom>
        Write Your Article
      </Typography>

      <TextField
        label="Title"
        variant="outlined"
        // value={title}
        // onChange={(e) => setTitle(e.target.value)}
        inputRef={titleRef}
        fullWidth
        required
      />

      <Editor
        style={{ height: '320px' }}
        value={content}
        onTextChange={(e) => {
          if (e.htmlValue == null) return;

          setContent(e.htmlValue);
        }}
      />

      {/* {image && <Typography variant="body2">Uploaded: {image.name}</Typography>} */}

      <Button 
        type="submit" 
        variant="contained" 
        color="primary"
        sx={{ alignSelf: 'flex-start' }}
      >
        Submit
      </Button>
      <LoadingDialog open={isLoading} />
    </Box>
  );
};

export default CreateArticlePage;