import { Box, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArticleCard from "../card/articleCard";
import { useArticleStore } from "../../../states/article-store";
import { useEffect } from "react";

type MenuProps = {
  title: string;
  orderBy: string;
};

const ArticlesSideMenu: React.FC<MenuProps> = ({ title, orderBy }) => {
  const { articles, fetchArticles } = useArticleStore();

  if (orderBy === "createdAt")
    articles.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

  const geraLink = (article: any) => `/artigos/s/${article.slug}`;

  useEffect(() => {
    if (!articles.length) {
      fetchArticles();
    }
  }, [articles]);

  return (
    <Box
      sx={{
        width: "30%",
        display: "flex",
        flexDirection: "column",
        // border: "1px solid blue",
        // width: "300px",
        backgroundColor: "#fff",
        height: "fit-content",
        minHeight: "600px",
        padding: "1rem",
        borderRadius: "5px",
        boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
        }}
      >
        {title || "Artigos"}
      </Typography>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
          // backgroundColor: "pink",
        }}
      >
        {articles.map((article, index) => (
          <Link
            to={geraLink(article)}
            style={{
              textDecoration: "none",
              color: "black",
              padding: "0",
              // border: "1px solid pink",
              width: "100%",
            }}
            key={index}
          >
            <ArticleCard article={article} minify={true} />
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default ArticlesSideMenu;
