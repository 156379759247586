import { Article } from "../../../domain/entities/article";
import { GetArticle } from "../../../domain/usecases/article/get-article";
import ApiError from "../../../infra/http/api-error";
import { AppConfig } from "../../../main/appconfig";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteGetArticle implements GetArticle {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async get(): Promise<Article> {
    const apiToken = AppConfig.API_TOKEN;
    const httpResponse = await this.httpPostClient.get({
      url: this.url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
    });

    const result = httpResponse?.data ? httpResponse.data : httpResponse;

    if (result.error) {
      throw new ApiError(result.error);
    }

    return result;
  }

  async getByPage(page: number): Promise<Article[]> {
    try {
      const apiToken = AppConfig.API_TOKEN;
      const httpResponse = await this.httpPostClient.get({
        url: this.url + `?page=${page}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken}`,
        },
      });

      return httpResponse.data.map((article: any) => ({
        _id: article._id,
        title: article.title,
        content: article.content,
        cover: article.cover,
        createdAt: article.createdAt,
        slug: article.slug,
      }));
    } catch (error) {
      throw new Error("Erro ao obter artigo");
    }
  }
}
