import { create } from "zustand";
import { Article } from "../../domain/entities/article";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { RemoteGetArticle } from "../../data/usecases/article/remote-get-article";

type ArticleState = {
  articles: Article[];
  setArticles: (articles: ArticleState["articles"]) => void;
  clearArticles: () => void;
  addArticle: (article: Article) => void;
  fetchArticles: () => void;
  shouldRefresh: boolean;
  setShouldRefresh: (shouldRefresh: boolean) => void;
  removeArticle: (id: string) => void;
};

export const useArticleStore = create<ArticleState>((set) => ({
  articles: [],
  setArticles: (articles) => set({ articles }),
  clearArticles: () => set({ articles: [] }),
  addArticle: (article) =>
    set((state) => ({
      articles: [...state.articles, article],
    })),
  fetchArticles: () => {
    // fetch articles
    const axiosHttpClient = new AxiosHttpClient();
    const remoteLoadArticles = new RemoteGetArticle(
      "/articles",
      axiosHttpClient
    );

    remoteLoadArticles.getByPage(1).then((articles) => {
      set({ articles });
    });
  },
  shouldRefresh: false,
  setShouldRefresh: (shouldRefresh) => set({ shouldRefresh }),
  removeArticle: (id) => {
    set((state) => ({
      articles: state.articles.filter((article) => article._id !== id),
    }));
  },
}));
