import { Article } from "../../../../domain/entities/article";
import { Box, Typography } from "@mui/material";
import defaultCover from "../../../assets/images/default-cover.jpg";

type ArticleCardProps = {
  article: Article;
  minify: boolean;
};

const ArticleCard: React.FC<ArticleCardProps> = ({ article, minify }) => {
  const getImageFromContent = (content: string) => {
    // the image is made like this (imagename)[imageurl], so we need to get the url
    const regex = /\(([^)]+)\)\[([^\]]+)\]/g;
    let match;

    while ((match = regex.exec(content)) !== null) {
      return match[2];
    }
  };

  const getTextFromHtmlContent = (content: string) => {
    const regex = /(<([^>]+)>)/gi;
    return content.replace(regex, "");
  }

  const getContentFirstParagraph = (content: string) => {
    const paragraph = getTextFromHtmlContent(content).split(".")[0];
    return paragraph.length > 120
      ? paragraph.slice(0, 120) + "..."
      : paragraph + ".";
  };

  var titleSize = minify ? "1.5em" : "2em";

  return (
    <Box
      sx={{
        display: "flex",
        // flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "start",
        padding: "1rem",
        height: minify ? "auto" : "200px",
        width: "100%",
        gap: "1rem",
        transition: "background-color 0.2s",
        borderBottom: "1px solid #f0f0f0",

        "&:hover": {
          backgroundColor: "#f0f0f0",
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "500",
            fontSize: titleSize
          }}
        >
          {article.title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#7a7a7a",
          }}
        >
          {getContentFirstParagraph(article.content)}
        </Typography>
      </Box>
      {!minify && (
        <img
          src={
            article.cover ||
            getImageFromContent(article.content) ||
            defaultCover
          }
          alt="cover"
          style={{
            height: "100%",
            aspectRatio: "14/9",
            objectFit: "cover"
          }}
        />
      )}
    </Box>
  );
};

export default ArticleCard;
