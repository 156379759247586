import { useState, useEffect } from "react";
import { GetProject } from "../../../domain/usecases/project/get-project";
import { Box, Typography } from "@mui/material";
import { Project } from "../../../domain/entities/project";
import { toast } from "react-toastify";
import { useUserStore } from "../../states/user-store";
import { DeleteProject } from "../../../domain/usecases/project/delete-project";
import { Link, useNavigate } from "react-router-dom";
import ProjectGallery from "../../components/project/gallery";
import { geraStringData } from "../../../application/global/funcoesGlobais";
import useWindowSize from "../../../application/hooks/useScreenSize";
import { CommentSectionFactory } from "../../../main/factories/components/comment-section-fact";
import PageAdminActions from "../../components/actions";

type Props = {
  getProject: GetProject;
  deleteProject: DeleteProject;
  projectId: string;
};

const SingleProjectPage: React.FC<Props> = ({
  getProject,
  deleteProject,
  projectId,
}) => {
  const [project, setProject] = useState<Project | null>(null);
  const { user } = useUserStore();
  const navigate = useNavigate();

  const { width } = useWindowSize();

  const loadProject = async () => {
    try {
      const response = await getProject.get({
        id: projectId,
      });

      if (response) {
        setProject(response);
      }
    } catch (error) {
      toast.error("Erro ao carregar projeto");
    }
  };

  const removeProject = async () => {
    try {
      const response = await deleteProject.delete({
        id: projectId,
      });

      if (response) {
        toast.success("Projeto deletado com sucesso");
        navigate("/projetos");
      }
    } catch (error) {
      toast.error("Erro ao deletar projeto");
    }
  };

  const showDescription = (description: string) => {
    const langKeywords = ["PT", "EN"];

    langKeywords.forEach((kw) => {
      description = description.replace(kw, `<b>${kw}</b>`);
    });

    return description;
  };

  const editAction = () => {
    navigate(`/projetos/edit/${projectId}`);
  };

  useEffect(() => {
    loadProject();
  }, []);

  return (
    project && (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          paddingBottom: width && width < 600 ? "5rem" : "1rem",
          gap: "1rem",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "1rem",
            left: "1rem",
          }}
        >
          <Link to="/projetos">Voltar</Link>
        </Box>
        <Typography variant="body1" sx={{ color: "#7a7a7a" }}>
          {geraStringData(project.createdAt)}
        </Typography>

        {/* project's info */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "flex-end",
            }}
          >
            <Typography variant={width && width < 600 ? "h5" : "h4"}>
              {project.title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#7a7a7a",
              }}
            >
              by {project.owner}
            </Typography>
          </Box>
        </Box>
        {/* end project's info */}
        {project.url && (
          <Link to={project.url} target="_blank">
            <Typography variant="body1" sx={{ color: "#7a7a7a" }}>
              {project.url}
            </Typography>
          </Link>
        )}

        {/* description */}
        {project.description && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              width: "80%",
              maxWidth: "800px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "pre-wrap",
                textAlign: "justify",
              }}
              dangerouslySetInnerHTML={{
                __html: showDescription(project.description),
              }}
            ></Typography>
          </Box>
        )}
        {/* end description */}

        {/* screenshots */}
        {project.screenshots.length > 0 && (
          <ProjectGallery images={project.screenshots} width={width} />
        )}
        {/* end screenshots */}

        {/* actions */}
        {user.admin && (
          <PageAdminActions
            editAction={editAction}
            confirmDelete={removeProject}
          />
        )}
        {/* end actions */}

        {/* contributors */}
        {project.contributors?.length > 0 && (
          <Box>
            <Typography variant="h6">Contribuidores</Typography>
            {project.contributors.map((contributor) => {
              return (
                <Typography variant="body1" sx={{ color: "#7a7a7a" }}>
                  {contributor}
                </Typography>
              );
            })}
          </Box>
        )}
        {/* end contributors */}

        {/* comments section */}
        {/* <Box
          sx={{
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <CommentSectionFactory postId={projectId} />
        </Box> */}
        {/* end comments section */}
      </Box>
    )
  );
};

export default SingleProjectPage;
