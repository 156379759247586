import { RemoteGetArticle } from "../../../../data/usecases/article/remote-get-article";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { useParams } from "react-router-dom";
import SingleArticlePage from "../../../../presentation/pages/article/article";
import { RemoteDeleteArticle } from "../../../../data/usecases/article/remote-delete-article";

export const ArticlePageFactory = () => {
  const { slug } = useParams<{ slug: string }>();
  const axiosHttpClient = new AxiosHttpClient();
  const remoteGetArticle = new RemoteGetArticle(
    `/articles/s/${slug}`,
    axiosHttpClient
  );
  const remoteDeleteArticle = new RemoteDeleteArticle(
    `/articles`,
    axiosHttpClient
  )

  return (
    <SingleArticlePage getArticle={remoteGetArticle} deleteArticle={remoteDeleteArticle} slug={slug ?? ''} />
  );
};
