import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";

type LoadingProps = {
  open: boolean;
  text?: string;
}

const LoadingDialog: React.FC<LoadingProps> = ({ open, text }) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <CircularProgress />
          <Typography variant="h6">{text || "Carregando..."}</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;