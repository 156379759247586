import { Box, Tooltip, Typography } from "@mui/material";
import { memo, useState } from "react";
import { Link } from "react-router-dom";
import { useUserStore } from "../../states/user-store";
import { useNavigate } from "react-router-dom";
import LogoutDialog from "../dialog/logout-dialog";
import LoginIcon from "@mui/icons-material/Login";
import UserMenu from "../auth/user-menu";

type Props = {
  mobile: boolean;
};

const Header: React.FC<Props> = ({ mobile }) => {
  const [open, setOpen] = useState(false);
  const { user, logout } = useUserStore();
  const navigate = useNavigate();

  const linkStyle = {
    textDecoration: "none",
    color: "black",
  };

  const handleLogButton = () => {
    if (user.username) {
      setOpen(true);
    } else {
      navigate("/login");
    }
  };

  const handleLogOut = () => {
    setOpen(false);
    logout();
    navigate("/login");
  };

  return (
    <Box
      sx={{
        padding: ".8em 1rem",
      }}
    >
      <LogoutDialog
        open={open}
        onClose={() => setOpen(false)}
        confirm={handleLogOut}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          borderBottom: "1px solid black",
          paddingBottom: "1rem",
        }}
      >
        <Link to="/" style={linkStyle}>
          <Typography variant="h4">e-melo</Typography>
        </Link>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {!mobile && (
            <>
              <Link to="/projetos" style={linkStyle}>
                <Typography variant="h6">projetos</Typography>
              </Link>
              <Link to="/artigos" style={linkStyle}>
                <Typography variant="h6">artigos</Typography>
              </Link>
              <Link to="/sobre" style={linkStyle}>
                <Typography variant="h6">sobre</Typography>
              </Link>
            </>
          )}
          {/* <Tooltip
            title={user.username ? "sair" : "entrar"}
            placement="top"
            onClick={handleLogButton}
          >
            <Box
              sx={{
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                marginLeft: "1rem",
                border: `1px solid ${user.username ? "red" : "black"}`,
                borderRadius: "5px",
                padding: "8px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                }}
              >
                {user ? user.username : "kdosadasodak"}
              </Typography>
              <LoginIcon />
            </Box>
          </Tooltip> */}
          <UserMenu handleLog={handleLogButton} />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(Header);
