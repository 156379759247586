import { useState } from "react";
import { Box, Button, TextField, CircularProgress } from "@mui/material";
import { eStyles } from "../../../application/global/e-styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RegisterUser } from "../../../domain/usecases/auth/register-user";

type Props = {
  authentication: RegisterUser;
};

const Register: React.FC<Props> = ({ authentication }) => {
  const [form, setForm] = useState({
    name: "",
    username: "",
    password: "",
    repassword: "",
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { name, username, password, repassword } = form;

    if (password !== repassword) {
      toast.error("As senhas não coincidem!");
      return;
    }

    setLoading(true);

    try {
      const response = await authentication.register({
        name,
        username,
        password,
      });

      if (response) {
        navigate("/login");
        toast.success("Registro efetuado com sucesso! Faça login para continuar.");
      }
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        margin: 0,
        minHeight: "70vh",
      }}
    >
      <h1>Cadastro</h1>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "300px",
            maxHeight: "90%",
          }}
        >
          <TextField
            id="name"
            name="name"
            label="Name"
            variant="outlined"
            value={form.name}
            onChange={handleChange}
            sx={eStyles.input}
          />
          <TextField
            id="username"
            name="username"
            label="Username"
            variant="outlined"
            value={form.username}
            onChange={handleChange}
            sx={eStyles.input}
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            variant="outlined"
            value={form.password}
            onChange={handleChange}
            sx={eStyles.input}
            inputProps={{
              type: "password",
            }}
          />
          <TextField
            id="repassword"
            name="repassword"
            label="Confirm Password"
            variant="outlined"
            value={form.repassword}
            onChange={handleChange}
            sx={eStyles.input}
            inputProps={{
              type: "password",
            }}
          />
          <Button
            variant="outlined"
            type="submit"
            sx={{
              backgroundColor: "#fff",
              transition: "background-color 0.2s",
              border: "1px solid #000",
              color: "#000",

              "&:hover": {
                backgroundColor: "#000",
                color: "#fff",
              },
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Cadastrar"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Register;
