import { Article } from "../../../domain/entities/article";
import { PostArticle } from "../../../domain/usecases/article/post-article";
import { AppConfig } from "../../../main/appconfig";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemotePostArticle implements PostArticle {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async post({ title, content }: PostArticle.Params): Promise<Article> {
    try {
      const apiToken = AppConfig.API_TOKEN;
      const httpResponse = await this.httpPostClient.post({
        url: this.url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken}`,
        },
        body: {
          title,
          content,
        },
      });

      const result = httpResponse.data;

      if (result.error) {
        throw new Error(result.error);
      }

      // const { slug } = result.data;

      return result.data;
    } catch (error) {
      throw error;
    }
  }
}
