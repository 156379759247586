import { Box, Button, Card, Modal, Typography } from "@mui/material";
import * as React from "react";

import { confirmable, ConfirmDialog } from "react-confirm";

interface Props {
  okLabel?: string;
  cancelLabel?: string;
  title?: string;
  confirmation?: string;
}

const Confirmation: ConfirmDialog<Props, boolean> = (props) => (
  <Modal
    open={props.show}
    onClose={() => props.proceed(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 1,
    }}
  >
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        width: "300px",
        backgroundColor: "#fff",
        borderRadius: "5px",
        boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1)",
      }}>
      <Typography variant="h5">{props.title || "Confirmação"}</Typography>
      <Typography variant="body1">{props.confirmation}</Typography>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          justifyContent: "flex-end",
        }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => props.proceed(true)}>
          {props.okLabel || "OK"}
          </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => props.proceed(false)}>
          {props.cancelLabel || "Cancelar"}
          </Button>
        </Box>
      </Card>
  </Modal>
);

export default confirmable(Confirmation);
