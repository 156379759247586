import moment from "moment";

export const geraStringData = (data: Date) => {
  data = new Date(data);
  const ano = data.getFullYear();
  const mes = String(data.getMonth() + 1).padStart(2, "0"); // Adiciona um zero à esquerda se o mês for de um dígito
  const dia = String(data.getDate()).padStart(2, "0"); // Adiciona um zero à esquerda se o dia for de um dígito

  return `${dia}/${mes}/${ano}`;
};

export const geraTempoPassado = (data: Date) => {
  const dataMoment = moment(data);
  const diferencaEmMinutos = moment().diff(dataMoment, "minutes");
  const diferencaEmHoras = moment().diff(dataMoment, "hours");
  const diferencaEmDias = moment().diff(dataMoment, "days");

  if (diferencaEmMinutos < 1) {
    return "Agora mesmo";
  } else if (diferencaEmMinutos < 60) {
    return `Há ${diferencaEmMinutos} ${
      diferencaEmMinutos === 1 ? "minuto" : "minutos"
    } `;
  }
  if (diferencaEmHoras < 24) {
    return `Há ${diferencaEmHoras} ${diferencaEmHoras === 1 ? "hora" : "horas"}`;
  }
   else if (diferencaEmDias === 1) {
    return "Ontem";
  } else if (diferencaEmDias < 7) {
    return `Há ${diferencaEmDias} ${
      diferencaEmDias === 1 ? "dia" : "dias"
    }`;
  } else if (dataMoment.isSame(moment(), "year")) {
    return dataMoment.format("DD/MM");
  }
  return dataMoment.format("DD-MM-YYYY");
};
