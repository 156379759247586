import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { articleListTemplate } from "../../../tests/templates/articles/template";
import ArticleCard from "../../components/article/card/articleCard";
import { Box, Button, Typography } from "@mui/material";
import { Article } from "../../../domain/entities/article";
import { useUserStore } from "../../states/user-store";
import useWindowSize from "../../../application/hooks/useScreenSize";

type ArticlesPageProps = {
  articles: Article[];
};

const ArticlesPage = ({ articles }: ArticlesPageProps) => {
  const { user } = useUserStore();
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const goToCreateArticle = () => {
    navigate("/artigos/create");
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "1em",
        gap: "1rem",
        // border: "1px solid black",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant={width && width < 600 ? "h5" : "h3"}>
          Artigos
        </Typography>
        {user.admin && (
          <Button
            variant="outlined"
            color="primary"
            onClick={goToCreateArticle}
          >
            Criar artigo
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          borderTop: "1px solid #e0e0e0",
          width: "100%",
        }}
      >
        {articles.map((artigo, index) => (
          <Link
            to={`/artigos/s/${artigo.slug}`}
            style={{
              textDecoration: "none",
              color: "black",
              padding: "0",
              borderBottom: "1px solid #e0e0e0",
              width: "100%",
            }}
            key={index}
          >
            <ArticleCard article={artigo} minify={false} />
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default ArticlesPage;
