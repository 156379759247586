import { useEffect, useState } from "react";
import { RemoteGetArticle } from "../../../../data/usecases/article/remote-get-article";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import ArticlesPage from "../../../../presentation/pages/article/list";
// import { Article } from "../../../../domain/entities/article";
import { useArticleStore } from "../../../../presentation/states/article-store";

export const ArticleListFactory = () => {
  const { articles, setArticles, shouldRefresh } = useArticleStore();
  // const [articleList, setArticleList] = useState<Article[]>([]);
  const axiosHttpClient = new AxiosHttpClient();
  const remoteLoadArticles = new RemoteGetArticle("/articles", axiosHttpClient);

  useEffect(() => {
    // var articlesSource = articles;

    if (!articles.length || shouldRefresh) {
      remoteLoadArticles.getByPage(1).then((articles) => {
        // articlesSource = articles;
        setArticles(articles);
      });
    }
    // setArticleList(articlesSource);
  }, []);

  return <ArticlesPage articles={articles} />;
};
