import React from "react";
import { Box, Button } from "@mui/material";
import { confirmable, createConfirmation } from "react-confirm";
import Confirmation from "./dialog/confimable";

type Props = {
  editAction: () => void;
  confirmDelete: () => void;
};

const PageAdminActions: React.FC<Props> = ({ editAction, confirmDelete }) => {
  const confirm = createConfirmation(Confirmation);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        gap: "1rem",
        width: "100%",
      }}
    >
      <Button variant="contained" color="info" onClick={editAction}>
        Editar
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          await confirm({
            confirmation: "Tem certeza que deseja deletar o artigo?",
            okLabel: "Deletar",
            cancelLabel: "Cancelar",
          }).then((confirmed) => {
            if (confirmed) {
              confirmDelete();
            }
          });
        }}
      >
        Deletar
      </Button>
    </Box>
  );
};

export default PageAdminActions;
