import { Project } from "../../../domain/entities/project";
import { GetProject } from "../../../domain/usecases/project/get-project";
import { HttpPostClient } from "../../protocols/http/http-post-client";
import { AppConfig } from "../../../main/appconfig";

export class RemoteGetProject implements GetProject {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async get({ id }: GetProject.Params): Promise<Project> {
    const apiToken = AppConfig.API_TOKEN;
    try {
      const urlRequest = `${this.url}/i/${id}`;
      const httpResponse = await this.httpPostClient.get({
        url: urlRequest,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken}`,
        },
      });

      const {
        _id,
        title,
        description,
        owner,
        contributors,
        screenshots,
        createdAt,
        lastUpdatedAt,
        tags,
        url,
        shown
      } = httpResponse?.data ? httpResponse.data[0] : httpResponse;

      return {
        _id,
        title,
        description,
        owner,
        contributors,
        screenshots,
        createdAt,
        lastUpdatedAt,
        tags,
        url,
        shown
      };
    } catch (error) {
      throw new Error("Erro ao obter projeto");
    }
  }

  async getAll(): Promise<Project[]> {
    try {
      const apiToken = AppConfig.API_TOKEN;
      const httpResponse = await this.httpPostClient.get({
        url: this.url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken}`,
        },
      });

      const projects: Project[] = httpResponse?.data
        ? httpResponse.data
        : httpResponse;

      return projects.map(
        ({
          _id,
          title,
          description,
          owner,
          contributors,
          screenshots,
          createdAt,
          lastUpdatedAt,
          tags,
          url,
          shown
        }) => ({
          _id,
          title,
          description,
          owner,
          contributors,
          screenshots,
          createdAt,
          lastUpdatedAt,
          tags,
          url,
          shown
        })
      );
    } catch (e) {
      throw new Error("Erro ao obter projetos");
    }
  }
}
