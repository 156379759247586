import {
  Container,
  Typography,
  Box,
  Divider,
  CircularProgress,
  Button,
} from "@mui/material";
import ArticlesSideMenu from "../../components/article/menu/articlesMenu";
import ScrollToTopOnMount from "../../../application/global/scroll-to-top";
import { GetArticle } from "../../../domain/usecases/article/get-article";
import { DeleteArticle } from "../../../domain/usecases/article/remove-article";
import { Article } from "../../../domain/entities/article";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./article.css";
import { useUserStore } from "../../states/user-store";
import { useNavigate } from "react-router-dom";
import PageAdminActions from "../../components/actions";
import { useArticleStore } from "../../states/article-store";
import LoadingDialog from "../../components/dialog/loading-dialog";

type SingleArticlePageProps = {
  slug: string;
  getArticle: GetArticle;
  deleteArticle: DeleteArticle;
};

const SingleArticlePage: React.FC<SingleArticlePageProps> = ({
  getArticle,
  deleteArticle,
  slug,
}) => {
  const [article, setArticle] = useState<Article | null | undefined>(undefined);
  const { user } = useUserStore();
  const { removeArticle } = useArticleStore();
  const navigate = useNavigate();

  const loadArticle = async () => {
    var article: Article | null | undefined = null;
    try {
      article = await getArticle.get({
        slug,
      });

    } catch (error: any) {
      if (error.name == "ApiError") {
        toast.error(error.message);
      } else {
        toast.error("Erro ao carregar artigo");
      }
    } finally {
      setArticle(article);
    }
  };

  const handleDelete = async () => {
    if (article) {
      try {
        const deleted = await deleteArticle.delete({
          id: article._id,
        });

        if (deleted) {
          toast.success("Artigo deletado com sucesso");
          removeArticle(article._id);
          navigate("/artigos");
        } else {
          toast.error("Erro ao deletar artigo");
        }
      } catch (error) {
        toast.error("Erro ao deletar artigo");
      }
    }
  };

  useEffect(() => {
    loadArticle();
  }, [slug]);

  return article ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "1rem",
        gap: "1rem",
        height: "100%",
        // border: "1px solid black"
        // backgroundColor: "blue",
        backgroundColor: "#f5f5f5",
        position: "relative",
      }}
    >
      <ScrollToTopOnMount />
      <Container
        sx={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          // border: "1px solid black",
          margin: 0,
          // overflowX: "auto",
          height: "auto",
          backgroundColor: "#fff",
          padding: "1rem",
          borderRadius: "5px",
          boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
            }}
          >
            {article.title}
          </Typography>
          <Typography variant="body1">
            {article.createdAt
              ? new Date(article.createdAt).toLocaleDateString()
              : ""}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            // border: "1px solid black",
            width: "100%",
          }}
        >
          <div
          className="article-content"
            style={{
              width: "100%",
              position: "relative",
              // overflow: "hidden",
            }}
            dangerouslySetInnerHTML={{ __html: article.content }}
          />
        </Box>
        {user && user.admin && (
          <PageAdminActions
            editAction={() => {}}
            confirmDelete={handleDelete}
          />
        )}
      </Container>
      <Divider orientation="vertical" flexItem />
      <ArticlesSideMenu title="Últimos artigos" orderBy="createdAt" />
    </Box>
  ) : article === null ? (
    <Typography variant="h2">Artigo não encontrado</Typography>
  ) : (
    <LoadingDialog open={true} text={"Carregando artigo..."} />
  );
};

export default SingleArticlePage;
