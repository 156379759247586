import { RemoteGetArticle } from "../../../../data/usecases/article/remote-get-article";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { useParams } from "react-router-dom";
import SingleArticlePage from "../../../../presentation/pages/article/article";
import CreateArticlePage from "../../../../presentation/pages/article/create";
import { RemotePostArticle } from "../../../../data/usecases/article/remote-post-article";

export const CreateArticlePageFactory = () => {
  const { slug } = useParams<{ slug: string }>();
  const axiosHttpClient = new AxiosHttpClient();
  const remotePostArticle = new RemotePostArticle(
    `/articles`,
    axiosHttpClient
  )

  return (
    <CreateArticlePage postArticle={remotePostArticle} />
  );
};
